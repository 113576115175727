export const MenuItem=[
    {
        title: 'Home',
        url: "/",
        cName:"nav-links",
        // icon:"fa-solid fa-house-user"
    },

    {
        title: 'About Us',
        url: "/details",
        cName:"nav-links",
        // icon:"fa-solid fa-circle-user"
    },
    {
        title: 'Service',
        url: "/service",
        cName:"nav-links",
        // icon:"fa-solid fa-briefcase"
    },
    {
        title: 'Contact',
        url: "/contact",
        cName:"nav-links",
        // icon:"fa-solid fa-address-book"
    },
    {
        title: 'Sign Up',
        url: "/signup",
        cName:"nav-links-mobile",
        
    },
]